// Login page Animation
export const isLoginPageAnimationEnabled = true;

// Login Page Style
export const isDefaultStyle = false; // true or false

//Logo -- Place your image in folder `src/assets/images`
export const logoPath = "lb_logo2.png";

//Pendo Analytics
export const pendoKey = '055e0a8b-b58d-43b8-6bc3-991ad9ea3974';
export const studyURL = "sgntuc-029.longboat.com";
export const sponsorName = "Seagen";
export const studyId = "SEAG394";
export const AppType = "CN";
export const pendoAccountName = studyId + '_' + AppType;

// Authentication
export const cookieExpiryTimeInMins = 5;
export const passwords = [{
        key: "pass", // key used for storing this password in cookie
        value: "SGNTUC-029", // password
        toRoute: "/", // go to this route/url after successful authentication
    },
    {
        key: "passRev",
        value: "Longboat!Rev",
        toRoute: "amend",
    },
];

// i18n localisation configuration
export const defaultLocale = "en";
export const supportedLocales = [
    "en",
    // "es"
];