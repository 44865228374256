import UiVideo from "@/components/WistiaVideo.vue";
import ConsentLayout from "@/layout/ConsentLayout.vue";

export default {
    components: {
        "consent-layout": ConsentLayout,
        "cn-video": UiVideo,
    },
    data() {
        return {
            reloadVideosKey: new Date().getSeconds()
        };
    },
    methods: {
        reloadVideos() {
            this.reloadVideosKey = new Date().getHours() + new Date().getMinutes() + new Date().getSeconds();
        }
    },

}