<template>
<consent-layout @dropdownLocaleChangeEvent="reloadVideos()">
    <div  :key="reloadVideosKey">
         <cn-video  style="margin-bottom: 48px"  :video="$t('video_one')" :videoTitle="$t('video_caption_one')" :videoText="$t('video_caption_one_text')" class=""></cn-video>
        <cn-video  style="margin-bottom: 48px"  :video="$t('video_two')" :videoTitle="$t('video_caption_two')" :videoText="$t('video_caption_two_text')" class="js-scroll"></cn-video>
        <cn-video  style="margin-bottom: 48px"  :video="$t('video_three')" :videoTitle="$t('video_caption_three')" :videoText="$t('video_caption_three_text')" class="js-scroll"></cn-video>
        <cn-video  style="margin-bottom: 48px"  :video="$t('video_four')" :videoTitle="$t('video_caption_four')" :videoText="$t('video_caption_four_text')" class="js-scroll"></cn-video>
        <cn-video  style="margin-bottom: 48px"  :video="$t('video_five')" :videoTitle="$t('video_caption_five')" :videoText="$t('video_caption_five_text')" class="js-scroll"></cn-video>
    </div>
</consent-layout>
</template>

<script>
import mixin from '@/common/mixin';

export default {
    mixins: [mixin],
};
</script>
