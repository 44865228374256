<template>
  <div class="copyright">
    <p class="copyright__text">
      Copyright © {{ new Date().getFullYear() }} Advarra, Inc. |
      <a
        class="copyright__link"
        target="_blank"
        :href="'https://app.longboat.com/gateway/privacy?locale=' + locale"
        v-html="$t('c_privacy')"
        ></a
      >
    </p>
  </div>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      required: true,
    }
  } ,// ["locale"],
};
</script>

<style lang="scss">
.copyright {
  margin: auto;
  margin-top: 100px;
  max-width: 1260px;
  padding: 0 16px;

  &__text {
    font-size: 14px;
  }

  &__link{
    text-decoration: none;
        color: inherit;
    &:active, &:hover, &:visited{
      color: inherit;
      text-decoration: none;
    }
    &:hover{
      text-decoration: underline;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .copyright {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 30px;
    left: auto;
  }
}
</style>